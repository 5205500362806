
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyButton } from "housfy-ui-lib";

@Component({
  name: "MailButton",
  components: { HousfySvg, HousfyButton },
})
export default class WhatsappButton extends Vue {
  @Prop({ type: String, required: true })
  mail?: string;

  handleButtonClick() {
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = `mailto:${this.mail}`;
  }
}
